<script>
export default {
  name: 'TutoRedir',

  created() {
    window._sketchy_open_tuto = true;
    this.$router.push({name: 'Accueil'});
  },
}
</script>
